import { useEffect, useRef, useState } from "react";


// TODO: Content manager (insert into database)

export default () => {

    const [content, setContent] = useState(null);
    const pagerRef = useRef(null);

    useEffect(()=>{
        fetch("assets/images/linkedin/news.json")
            .then(res => res.json())
            .then(data => setContent(data?.news))
            .catch(err => console.log(err));
    },[])

    useEffect(() => {
        if(pagerRef.current == null) return;
        // invert scroll on news pager
        pagerRef.current.addEventListener('wheel', e => {
            if (pager.scrollLeft == 0 && e.deltaY < 0) return;
            if (pager.scrollLeft + pager.clientWidth >= pager.scrollWidth && e.deltaY > 0) return;
            e.preventDefault();
            // termporally disable snap scrolling
            pager.style.scrollSnapType = 'none';
            pager.scrollLeft += e.deltaY > 0 ? 300 : -300;
            // Re-enable snap scrolling
            setTimeout(() => {
                pager.style.scrollSnapType = 'x mandatory';
            }, 50);
        })
    }, [content])
    
    if(content == null) return (<></>)

    return (
        // background color matching banner background
        <section className="fw dark-blue">
            <strong>Nuestras últimas noticias en LinkedIn</strong>
            <div ref={pagerRef} id="news-pager" className="pager" data-aos="fade-up">
                {
                    content.map((post, index) => (
                        <a role="navigation" aria-label={`Ampliar noticia ${post.title} en LinkedIn.`} target="_blank" href={post.link} key={`news-card-${index}`} className="newsCard" style={{ backgroundImage: `url(${post.image})` }}>
                            <span>{post.title}</span>
                        </a>
                    ))
                }
            </div>
        </section>
    );
}